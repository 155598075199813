import { TitleStyled, useResponsiveBooleansContext } from 'backgammon_ui_shared';
import React from 'react';
import { BetaRibbon, Container, Icon, SmallIcon } from './CarouselItem.styled';
import { getStaticResource } from '../../../../../shared';
import { isDesktopScreen } from '../../../../../app.constants';

interface Props {
  title: string;
  iconPath?: string;
  disabled?: boolean;
  onClick?(): void;
  background?: string;
  isBeta?: boolean;
  betaText?: string | number;
  isSmallSVG?: boolean;
}

function CarouselItem({
  title,
  iconPath,
  onClick,
  isBeta = false,
  betaText = 'BETA',
  disabled,
  background,
  isSmallSVG,
}: Props) {
  const { isSuperSmall, isTabOrScreen } = useResponsiveBooleansContext();

  return (
    <Container
      isDesktop={isDesktopScreen}
      disabled={disabled}
      onClick={onClick}
      isTabOrScreen={isTabOrScreen}
      isSuperSmall={isSuperSmall}
      background={background}
    >
      {isBeta ? <BetaRibbon>{betaText}</BetaRibbon> : null}
      {isDesktopScreen ? (
        <SmallIcon isSmall={isSmallSVG} src={getStaticResource(iconPath)} />
      ) : (
        <Icon
          isSmallSVG={isSmallSVG}
          isSuperSmall={isSuperSmall}
          isTabOrScreen={isTabOrScreen}
          iconPath={getStaticResource(iconPath)}
        />
      )}
      <TitleStyled
        isCentered={true}
        marginTop={isTabOrScreen ? -30 : 0}
        isStretched={false}
        isInUpperCase={false}
        isSubHeading={true}
        color="text"
        fontSize={isTabOrScreen ? 'large' : 'regular'}
      >
        {title}
      </TitleStyled>
    </Container>
  );
}

export default CarouselItem;
